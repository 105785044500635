import './style.css';

import ceicatLogo from './metadata/ceicatLogoALT.png';
import musicIcon from './metadata/music-icon.png';
import ceicatImg from './metadata/ceicat-cat-logo.png';
import ceicatCollageImg from './metadata/ceicatCollage.png';
import ceicatLordImg from './metadata/ceicatLord.jpeg';
import ceicatOriginsMp4 from './metadata/video/CEICAT(1996)_To-The-Stars_scene[1080]-compressed.mp4';
import defaultBgImg from './metadata/Sandy.webp';

const addTopTextSVG = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;height:16px"> <path d="M19 4H3v2h16V4zm0 4H3v2h16V8zM3 12h8v2H3v-2zm8 4H3v2h8v-2zm7-1h3v2h-3v3h-2v-3h-3v-2h3v-3h2v3z" fill="currentColor"/> </svg>`;
const addBottomTextSVG = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;height:16px"> <path d="M19 5H3v2h16v6h-6v-2h2V9h-2v2h-2v2H9v2h2v2h2v2h2v-2h-2v-2h6v-2h2V7h-2V5zM7 13H3v2h4v-2zM3 9h6v2H3V9z" fill="currentColor"/> </svg>`;
const acceptBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;height:16px"> <path d="M18 6h2v2h-2V6zm-2 4V8h2v2h-2zm-2 2v-2h2v2h-2zm-2 2h2v-2h-2v2zm-2 2h2v-2h-2v2zm-2 0v2h2v-2H8zm-2-2h2v2H6v-2zm0 0H4v-2h2v2z" fill="currentColor"/> </svg>`;
const rejectBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;height:16px"> <path d="M5 5h2v2H5V5zm4 4H7V7h2v2zm2 2H9V9h2v2zm2 0h-2v2H9v2H7v2H5v2h2v-2h2v-2h2v-2h2v2h2v2h2v2h2v-2h-2v-2h-2v-2h-2v-2zm2-2v2h-2V9h2zm2-2v2h-2V7h2zm0 0V5h2v2h-2z" fill="currentColor"/> </svg>`;
const resizeImgBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;height:16px"> <path d="M21 3h-8v2h4v2h2v4h2V3zm-4 4h-2v2h-2v2h2V9h2V7zm-8 8h2v-2H9v2H7v2h2v-2zm-4-2v4h2v2H5h6v2H3v-8h2z" fill="currentColor"/> </svg>`;
const moveImgBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;height:16px"> <path d="M13 0h-2v2H9v2H7v2h2V4h2v7H4V9h2V7H4v2H2v2H0v2h2v2h2v2h2v-2H4v-2h7v7H9v-2H7v2h2v2h2v2h2v-2h2v-2h2v-2h-2v2h-2v-7h7v2h-2v2h2v-2h2v-2h2v-2h-2V9h-2V7h-2v2h2v2h-7V4h2v2h2V4h-2V2h-2V0z" fill="currentColor"/> </svg>`;
const rotateImgBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;height:16px"> <path d="M4 9V7h12V5h2v2h2v2h-2v2h-2V9H4zm12 2h-2v2h2v-2zm0-6h-2V3h2v2zm4 12v-2H8v-2h2v-2H8v2H6v2H4v2h2v2h2v2h2v-2H8v-2h12z" fill="currentColor"/> </svg>`;
const delImgBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;height:16px"> <path d="M16 2v4h6v2h-2v14H4V8H2V6h6V2h8zm-2 2h-4v2h4V4zm0 4H6v12h12V8h-4zm-5 2h2v8H9v-8zm6 0h-2v8h2v-8z" fill="currentColor"/> </svg>`;
const downloadBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 20px; height: 20px;"> <path d="M13 17V3h-2v10H9v-2H7v2h2v2h2v2h2zm8 2v-4h-2v4H5v-4H3v6h18v-2zm-8-6v2h2v-2h2v-2h-2v2h-2z" fill="currentColor"/> </svg>`;
const bgBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 20px; height: 20px;"> <path d="M11 5V3h2v2h2v2h2v2h-2V7h-2v10h-2V7H9v2H7V7h2V5h2zM3 15v6h18v-6h-2v4H5v-4H3z" fill="currentColor"/> </svg>`;
const imgBtnSVG = `<svg class="memeAct-svg" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 20px; height: 20px;"> <path d="M11 5V3h2v2h2v2h2v2h-2V7h-2v10h-2V7H9v2H7V7h2V5h2zM3 15v6h18v-6h-2v4H5v-4H3z" fill="currentColor"/> </svg>`;

import ceicat_cryptoCat from './metadata/music/ceicat-crypto-cat.mp3';
import ceicat_cryptoKing from './metadata/music/ceicat-crypto-king.mp3'; 
import ceicat_eyes from './metadata/music/ceicat-eyes.mp3';
import ceicat_fever from './metadata/music/ceicat-fever.mp3';
import ceicat_sensation from './metadata/music/ceicat-sensation.mp3';
import ceicat_viral from './metadata/music/ceicat-viral.mp3';
import ceicat_catUndervalued from './metadata/music/ceiling-cat-undervalued.mp3';

// Backdrop
function backdropEffects() {

  const container = document.getElementById("backdropContainer");

  const blob = document.createElement('div');
  blob.classList.add("blob");
  const blobSvg = `
    <svg class="blob-svg" viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <path class="blob-path" fill="#b4befe" d="M397,264Q355,288,348,314.5Q341,341,305.5,326.5Q270,312,255,325.5Q240,339,196,395.5Q152,452,154.5,387.5Q157,323,116.5,315.5Q76,308,41.5,274Q7,240,49,209Q91,178,116.5,160Q142,142,152,97.5Q162,53,201,33Q240,13,265.5,65Q291,117,294,150Q297,183,357.5,174.5Q418,166,428.5,203Q439,240,397,264Z" />
    </svg>
  `;
  blob.innerHTML = blobSvg;
  container.appendChild(blob);

}

// Header
const header = document.querySelector('header');
const tokenLogo = document.createElement('img');
tokenLogo.src = ceicatLogo;
header.appendChild(tokenLogo);

// Main Content
const main = document.querySelector('main');

const catOrigins = document.createElement('div');
catOrigins.id = "catOrigins";

const catOriginsText = document.createElement('div');

const catOriginsH1 = document.createElement('h1');
catOriginsH1.innerHTML = `CEILING CAT ORIGINS`;

const catOriginsPara = document.createElement('p');
catOriginsPara.innerHTML = `
  Ceiling Cat is one of the earliest known internet memes dating back to 2003 when
  the original photograph of a ginger cat peering through a hole in the ceiling was submitted
  to <a href="https://knowyourmeme.com/memes/sites/funnyjunk" target="_blank">Funny Junk</a>. 
  Many variants of this image appeared on <a href="https://knowyourmeme.com/memes/sites/4chan" target="_blank">4chan's /b/</a>, 
  or random board, using the "cat in a hole" <a href="https://knowyourmeme.com/memes/exploitables" target="_blank">exploitable</a> 
  exploitable in a number of ways.`;

const catKnowMoreBtn = document.createElement('button');
catKnowMoreBtn.innerHTML = `Learn more about the meme`;

catOriginsText.appendChild(catOriginsH1);
catOriginsText.appendChild(catOriginsPara);
catOriginsText.appendChild(catKnowMoreBtn);
catOrigins.appendChild(catOriginsText);
main.appendChild(catOrigins);

function catOriginsVid() {
  const container = document.createElement('div');
  container.id = "catOriginsVidContainer";

  const video = document.createElement('video');
  video.id = "catOriginsVid";
  video.src = ceicatOriginsMp4;
  video.autoplay = true;
  video.muted = true;
  video.controls = true;

  container.appendChild(video);
  catOrigins.appendChild(container);
}

const catCollage = document.createElement('div');
catCollage.id = "catCollage";

const catCollageImg = document.createElement('img');
catCollageImg.src = ceicatCollageImg;
catCollage.appendChild(catCollageImg);

const pfpGenBtn = document.createElement('button');
pfpGenBtn.innerHTML = `Custom Ceiling Cat<br>Generator`;
catCollage.appendChild(pfpGenBtn);

main.appendChild(catCollage);

const catLord = document.createElement('div');
catLord.id = "catLord";

const catLordImg = document.createElement('img');
catLordImg.src = ceicatLordImg;

const catLordText = document.createElement('div');
catLordText.id = "catLordText";

const ceicatMemes = document.createElement('div');
const ceicatMemesText = document.createElement('span');
ceicatMemesText.innerHTML = `Ceicat Memes`;
ceicatMemes.appendChild(ceicatMemesText);
const galleryBtn = document.createElement('button');
galleryBtn.innerHTML = `Ceiling Cat Gallery`;
ceicatMemes.appendChild(galleryBtn);
catLordText.appendChild(ceicatMemes);

const ourVision = document.createElement('div');
const ourVisionText = document.createElement('span');
ourVisionText.innerHTML = `Our Vision`;
ourVision.appendChild(ourVisionText);
const whitepaperBtn = document.createElement('button');
whitepaperBtn.innerHTML = `Whitepaper`;
ourVision.appendChild(whitepaperBtn);
catLordText.appendChild(ourVision);

catLord.appendChild(catLordImg);
catLord.appendChild(catLordText);
main.appendChild(catLord);

// Meme generator 
const memeGenContainer = document.createElement('div');
memeGenContainer.id = "memeGenContainer";

function memeGen() {
  const infoH1 = document.createElement('span');
  infoH1.classList.add("infoH1");
  infoH1.innerHTML = `Meme Generator`;
  memeGenContainer.appendChild(infoH1);

  const uploadActions = document.createElement('div');
  uploadActions.classList.add("uploadActions");

  const bgBtn = document.createElement('button');
  bgBtn.innerHTML = `${bgBtnSVG} Backdrop`;
  uploadActions.appendChild(bgBtn);

  const imgBtn = document.createElement('button');
  imgBtn.innerHTML = `${imgBtnSVG} Image`;
  uploadActions.appendChild(imgBtn);

  const downloadBtn = document.createElement('button');
  downloadBtn.innerHTML = `${downloadBtnSVG} Download`;
  downloadBtn.id = "downloadBtn";
  uploadActions.appendChild(downloadBtn);

  const bgFileInput = document.createElement('input');
  bgFileInput.type = 'file';
  bgFileInput.accept = 'image/*';
  bgFileInput.style.display = 'none';
  uploadActions.appendChild(bgFileInput);

  const imgFileInput = document.createElement('input');
  imgFileInput.type = 'file';
  imgFileInput.accept = 'image/*';
  imgFileInput.style.display = 'none';
  uploadActions.appendChild(imgFileInput);

  memeGenContainer.appendChild(uploadActions); 

  const canvas = document.createElement('canvas');
  canvas.width = 1024;
  canvas.height = 1024;
  memeGenContainer.appendChild(canvas);

  const imgActions = document.createElement('div');
  imgActions.classList.add("imgActions");

  const acceptBtn = document.createElement('button');
  acceptBtn.id = "actionAcceptBtn";
  acceptBtn.disabled = true;
  acceptBtn.classList.add("imgAct-disabled");
  acceptBtn.innerHTML = acceptBtnSVG;
  imgActions.appendChild(acceptBtn);

  const rejectBtn = document.createElement('button');
  rejectBtn.id = "actionRejectBtn";
  rejectBtn.disabled = true;
  rejectBtn.classList.add("imgAct-disabled");
  rejectBtn.innerHTML = rejectBtnSVG;
  imgActions.appendChild(rejectBtn);

  const addTopTextBtn = document.createElement('button');
  addTopTextBtn.id = "addTopTextBtn";
  addTopTextBtn.innerHTML = `${addTopTextSVG} Top Text`;

  const addBottomTextBtn = document.createElement('button');
  addBottomTextBtn.id = "addBottomTextBtn";
  addBottomTextBtn.innerHTML = `${addBottomTextSVG} Bottom Text`;

  const resizeImgBtn = document.createElement('button');
  resizeImgBtn.innerHTML = `${resizeImgBtnSVG} Resize`;

  const moveImgBtn = document.createElement('button');
  moveImgBtn.innerHTML = `${moveImgBtnSVG} Move`;

  const rotateImgBtn = document.createElement('button');
  rotateImgBtn.innerHTML = `${rotateImgBtnSVG} Rotate`;

  const delImgBtn = document.createElement('button');
  delImgBtn.innerHTML = `${delImgBtnSVG} Delete`;
  
  imgActions.appendChild(addTopTextBtn);
  imgActions.appendChild(resizeImgBtn);
  imgActions.appendChild(rotateImgBtn);
  imgActions.appendChild(addBottomTextBtn);
  imgActions.appendChild(moveImgBtn);
  imgActions.appendChild(delImgBtn);
  memeGenContainer.appendChild(imgActions);

  const textBox = document.createElement('textarea');
  textBox.id = "textBoxInput";
  textBox.setAttribute("type", "text");
  textBox.placeholder = `Enter Text.`;
  textBox.style.resize = 'none';
  textBox.rows = 2;
  textBox.style.display = 'none';
  memeGenContainer.appendChild(textBox);

  const rotateDegInput = document.createElement('textarea');
  rotateDegInput.id = "rotateDegInput";
  rotateDegInput.setAttribute("type", "number");
  rotateDegInput.placeholder = `Enter Rotation\nin degrees.`;
  rotateDegInput.style.resize = 'none';
  rotateDegInput.rows = 2;
  rotateDegInput.style.display = 'none';
  memeGenContainer.appendChild(rotateDegInput);

  rotateDegInput.addEventListener('input', function() {
    this.value = this.value.replace(/[^0-9\-]+/g, ''); // Allow digits and negative sign
  });

  const ctx = canvas.getContext('2d');
  const userImgs = [];
  let lastMovedImg = null;
  let lastResizedImg = null;
  let activeMode = null;
  let selectedImg = null;
  let selectedImg_forRotate = null;
  let ceicatImgObj = null;

  let userBgImg = null;
  let bgImage = null;

  let topText = '';
  let bottomText = '';

  function loadBackgroundImage(src) {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      bgImage = img;
      updateCanvas();
    };
  }

  function loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  if (!userBgImg) {
    loadBackgroundImage(defaultBgImg);
  } else {
    bgImage = userBgImg;
  }

  let ceicatImgLoaded = false;
  function initializeDefaultImage() {
    if (!ceicatImgLoaded) {
      loadImage(ceicatImg).then((ceicatImg) => {
        const imgWidth = 200;
        const imgHeight = 125;
        const xAxis = (canvas.width - imgWidth) / 2;
        const yAxis = 52;

        ceicatImgObj = {
          img: ceicatImg,
          x: xAxis,
          y: yAxis,
          rotationAngle: 0,
          width: imgWidth,
          height: imgHeight
        };
        userImgs.push(ceicatImgObj);
        ceicatImgLoaded = true;
        updateCanvas();
      });
    }
  }

  function updateCanvas() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (bgImage) {
      ctx.drawImage(bgImage, 0, 0, canvas.width, canvas.height);
    }

    initializeDefaultImage();
    drawImages();
  }

  function drawImages() {
    userImgs.forEach(imgObj => {
      ctx.save();
        const centerX = imgObj.x + ((imgObj.width || imgObj.img.width) / 2);
        const centerY = imgObj.y + ((imgObj.height || imgObj.img.height) / 2);

        ctx.translate(centerX, centerY);
        if (imgObj.rotationAngle) {
            ctx.rotate(imgObj.rotationAngle * Math.PI / 180);
        }
        ctx.drawImage(imgObj.img, -((imgObj.width || imgObj.img.width) / 2), -((imgObj.height || imgObj.img.height) / 2), imgObj.width || imgObj.img.width, imgObj.height || imgObj.img.height);
        ctx.restore();
    });

    const memeFont = new FontFace("Impact", "url(/fonts/impact.ttf)");
    memeFont.load().then(function(loadedFont) {
      document.fonts.add(loadedFont);
    }).catch(function(error) {
      console.error('Failed to load font: ', error);
    }); 
    
    if (topText) {
      ctx.font = '48px Impact';
      ctx.textAlign = 'center';
      ctx.lineWidth = 8;
      ctx.strokeStyle = 'black';
      ctx.fillStyle = 'white';
      ctx.strokeText(topText, canvas.width / 2, 52);
      ctx.fillText(topText, canvas.width / 2, 52);
    }

    if (bottomText) {
      ctx.font = '48px Impact';
      ctx.textAlign = 'center';
      ctx.lineWidth = 8;
      ctx.strokeStyle = 'black';
      ctx.fillStyle = 'white';
      ctx.strokeText(bottomText, canvas.width / 2, canvas.height - 16);
      ctx.fillText(bottomText, canvas.width / 2, canvas.height - 16);
    }

  }

  function deactivateCurrentMode() {
    if (activeMode === 'move') {
      deactivateMoveMode();
    } else if (activeMode === 'resize') {
      deactivateResizeMode();
    } else if (activeMode === 'delete') {
      deactivateDeleteMode();
    } else if (activeMode === 'rotate') {
      deactivateRotateMode();
    } else if (activeMode === 'textTopMode') {
      deactivateTextTopMode();
    } else if (activeMode === 'textBottomMode') {
      deactivateTextBottomMode();
    }
    activeMode = null;
  }
  
  // TEXT INPUT FUNCTIONS
  function textTop() {
    activeMode = 'textTopMode';
    textBox.style.display = 'block';

    acceptBtn.classList.remove("imgAct-disabled");
    rejectBtn.classList.remove("imgAct-disabled");
    acceptBtn.disabled = false;
    rejectBtn.disabled = false;

    addBottomTextBtn.disabled = true;
    addBottomTextBtn.classList.add("imgAct-disabled");
    resizeImgBtn.disabled = true;
    resizeImgBtn.classList.add("imgAct-disabled");
    moveImgBtn.disabled = true;
    moveImgBtn.classList.add("imgAct-disabled");
    rotateImgBtn.disabled = true;
    rotateImgBtn.classList.add("imgAct-disabled");
    delImgBtn.disabled = true;
    delImgBtn.classList.add("imgAct-disabled");
  }
  function deactivateTextTopMode() {
    textBox.style.display = 'none';
    textBox.value = '';
    activeMode = null;
    console.log("Text Top mode deactivated.");
    rotateImgBtn.classList.remove("imgAct-selected");
    acceptBtn.classList.add("imgAct-disabled");
    rejectBtn.classList.add("imgAct-disabled");

    addBottomTextBtn.disabled = false;
    addBottomTextBtn.classList.remove("imgAct-disabled");
    resizeImgBtn.disabled = false;
    resizeImgBtn.classList.remove("imgAct-disabled");
    moveImgBtn.disabled = false;
    moveImgBtn.classList.remove("imgAct-disabled");
    rotateImgBtn.disabled = false;
    rotateImgBtn.classList.remove("imgAct-disabled");
    delImgBtn.disabled = false;
    delImgBtn.classList.remove("imgAct-disabled");
  }
  
  function textBottom() {
    activeMode = 'textBottomMode';
    textBox.style.display = 'block';

    acceptBtn.classList.remove("imgAct-disabled");
    rejectBtn.classList.remove("imgAct-disabled");
    acceptBtn.disabled = false;
    rejectBtn.disabled = false;

    addTopTextBtn.disabled = true;
    addTopTextBtn.classList.add("imgAct-disabled");
    resizeImgBtn.disabled = true;
    resizeImgBtn.classList.add("imgAct-disabled");
    moveImgBtn.disabled = true;
    moveImgBtn.classList.add("imgAct-disabled");
    rotateImgBtn.disabled = true;
    rotateImgBtn.classList.add("imgAct-disabled");
    delImgBtn.disabled = true;
    delImgBtn.classList.add("imgAct-disabled");

  }
  function deactivateTextBottomMode() {
    textBox.style.display = 'none';
    textBox.value = '';
    activeMode = null;
    console.log("Text Top mode deactivated.");
    rotateImgBtn.classList.remove("imgAct-selected");
    acceptBtn.classList.add("imgAct-disabled");
    rejectBtn.classList.add("imgAct-disabled");

    addTopTextBtn.disabled = false;
    addTopTextBtn.classList.remove("imgAct-disabled");
    resizeImgBtn.disabled = false;
    resizeImgBtn.classList.remove("imgAct-disabled");
    moveImgBtn.disabled = false;
    moveImgBtn.classList.remove("imgAct-disabled");
    rotateImgBtn.disabled = false;
    rotateImgBtn.classList.remove("imgAct-disabled");
    delImgBtn.disabled = false;
    delImgBtn.classList.remove("imgAct-disabled");
  }

  addTopTextBtn.addEventListener('click', function() {
    textTop();
  });

  addBottomTextBtn.addEventListener('click', function() {
    textBottom();
  });

  // RESIZE IMGS FUNCTIONS
  let resizing = false;

  function resize_onMouseDown(e) {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    selectedImg = null // Reset selected image at start of new selection
    userImgs.forEach((imgObj, index) => {
      const imgWidth = imgObj.width || imgObj.img.width;
      const imgHeight = imgObj.height || imgObj.img.height;
      console.log(`Checking image ${index} at (${imgObj.x}, ${imgObj.y}) with width ${imgWidth} and height ${imgHeight}`);

      if (x >= imgObj.x && x <= imgObj.x + imgWidth && y >= imgObj.y && y <= imgObj.y + imgHeight) {
        selectedImg = imgObj;
        startX = x;
        startY = y;
        console.log(`Image ${index} selected for movement.`);
        resizing = true;
        lastResizedImg = imgObj;
        lastResizedImg.originalWidth = imgWidth;
        lastResizedImg.originalHeight = imgHeight;
      }
    });

    if (!selectedImg) {
      console.log('No image was selected for resizing.');
      resizing = false;
    }
  }

  function resize_onMouseMove(e) {
    if (!selectedImg || !resizing) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const mouseX = (e.clientX - rect.left) * scaleX;
    const mouseY = (e.clientY - rect.top) * scaleY;

    const widthChange = mouseX - startX;
    const heightChange = mouseY - startY;

    selectedImg.width = (lastResizedImg.originalWidth + widthChange) > 0 ? lastResizedImg.originalWidth + widthChange : 1;
    selectedImg.height = (lastResizedImg.originalHeight + heightChange) > 0 ? lastResizedImg.originalHeight + heightChange : 1;

    updateCanvas();
  }

  function resize_onMouseUp() {
    if (selectedImg) {
      resizing = false;
    }
    selectedImg = null;
  }

  function resize_onTouchStart(e) {
    e.preventDefault(); // Prevents the default scrolling behavior
    const touch = e.touches[0];
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (touch.clientX - rect.left) * scaleX;
    const y = (touch.clientY - rect.top) * scaleY;

    selectedImg = null; // Reset selected image at start of new selection
    userImgs.forEach((imgObj, index) => {
      const imgWidth = imgObj.width || imgObj.img.width;
      const imgHeight = imgObj.height || imgObj.img.height;
      console.log(`Checking image ${index} at (${imgObj.x}, ${imgObj.y}) with width ${imgWidth} and height ${imgHeight}`);

      if (x >= imgObj.x && x <= imgObj.x + imgWidth && y >= imgObj.y && y <= imgObj.y + imgHeight) {
        selectedImg = imgObj;
        startX = x;
        startY = y;
        resizing = true;
        lastResizedImg = { ...imgObj, originalWidth: imgWidth, originalHeight: imgHeight }; // Store original dimensions before resizing
      }
    });

    if (!selectedImg) {
      resizing = false;
    }
  }

  function resize_onTouchMove(e) {
    e.preventDefault(); // Prevents the default scrolling behavior
    if (!selectedImg || !resizing) return;

    const touch = e.touches[0];
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const mouseX = (touch.clientX - rect.left) * scaleX;
    const mouseY = (touch.clientY - rect.top) * scaleY;

    const widthChange = mouseX - startX;
    const heightChange = mouseY - startY;

    selectedImg.width = (lastResizedImg.originalWidth + widthChange) > 0 ? lastResizedImg.originalWidth + widthChange : 1;
    selectedImg.height = (lastResizedImg.originalHeight + heightChange) > 0 ? lastResizedImg.originalHeight + heightChange : 1;

    updateCanvas();
  }

  function resize_onTouchEnd() {
    if (selectedImg) {
      resizing = false;
    }
    selectedImg = null;
  } 

  function activateResizeMode() {
    activeMode = 'resize';
    acceptBtn.disabled = false;
    acceptBtn.classList.remove("imgAct-disabled");
    rejectBtn.disabled = false;
    rejectBtn.classList.remove("imgAct-disabled");
    resizeImgBtn.classList.add("imgAct-selected");
    canvas.style.cursor = 'nesw-resize';
    moveImgBtn.disabled = true;
    moveImgBtn.classList.add("imgAct-disabled");
    rotateImgBtn.disabled = true;
    rotateImgBtn.classList.add("imgAct-disabled");
    delImgBtn.disabled = true;
    delImgBtn.classList.add("imgAct-disabled");
    addTopTextBtn.disabled = true;
    addTopTextBtn.classList.add("imgAct-disabled"); 
    addBottomTextBtn.disabled = true;
    addBottomTextBtn.classList.add("imgAct-disabled"); 

    canvas.addEventListener('mousedown', resize_onMouseDown);
    canvas.addEventListener('mousemove', resize_onMouseMove);
    canvas.addEventListener('mouseup', resize_onMouseUp);
    canvas.addEventListener('touchstart', resize_onTouchStart);
    canvas.addEventListener('touchmove', resize_onTouchMove);
    canvas.addEventListener('touchend', resize_onTouchEnd);
  }

  function deactivateResizeMode() {
    activeMode = null;
    console.log("Resize mode deactivated.");
    canvas.style.cursor = 'default';
    resizeImgBtn.classList.remove("imgAct-selected");
    acceptBtn.classList.add("imgAct-disabled");
    rejectBtn.classList.add("imgAct-disabled");
    acceptBtn.disabled = true;
    rejectBtn.disabled = true;
    moveImgBtn.disabled = false;
    moveImgBtn.classList.remove("imgAct-disabled");
    rotateImgBtn.disabled = false;
    rotateImgBtn.classList.remove("imgAct-disabled");
    delImgBtn.disabled = false;
    delImgBtn.classList.remove("imgAct-disabled");
    addTopTextBtn.disabled = false;
    addTopTextBtn.classList.remove("imgAct-disabled"); 
    addBottomTextBtn.disabled = false;
    addBottomTextBtn.classList.remove("imgAct-disabled"); 

    canvas.removeEventListener('mousedown', resize_onMouseDown);
    canvas.removeEventListener('mousemove', resize_onMouseMove);
    canvas.removeEventListener('mouseup', resize_onMouseUp);
    canvas.removeEventListener('touchstart', resize_onTouchStart);
    canvas.removeEventListener('touchmove', resize_onTouchMove);
    canvas.removeEventListener('touchend', resize_onTouchEnd);
    resizing = false;
  }

  // MOVE IMGS FUNCTIONS

  let startX, startY;

  function move_onMouseDown(e) {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;
    console.log(`Mouse down position: x=${x}, y=${y}`);

    selectedImg = null;  // Reset selected image at start of new selection
    userImgs.forEach((imgObj, index) => {
      const imgWidth = imgObj.width || imgObj.img.width;
      const imgHeight = imgObj.height || imgObj.img.height;
      console.log(`Checking image ${index} at (${imgObj.x}, ${imgObj.y}) with width ${imgWidth} and height ${imgHeight}`);

      if (x >= imgObj.x && x <= imgObj.x + imgWidth && y >= imgObj.y && y <= imgObj.y + imgHeight) {
        selectedImg = imgObj;
        startX = x - imgObj.x;
        startY = y - imgObj.y;
        console.log(`Image ${index} selected for movement.`);
        lastMovedImg = imgObj;
      }
    });

    if (!selectedImg) {
      console.log('No image was selected for movement.');
    }
  }

  function move_onMouseMove(e) {
    if (!selectedImg) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    selectedImg.x = x - startX;
    selectedImg.y = y - startY;
    console.log(`Moving image to x=${selectedImg.x}, y=${selectedImg.y}`);

    updateCanvas();
  }

  function move_onMouseUp() {
    if (selectedImg) {
      console.log(`Movement stopped for selected image at x=${selectedImg.x}, y=${selectedImg.y}`);
    }
    selectedImg = null;
  }

  function move_onTouchStart(e) {
    e.preventDefault(); // Prevents the default scrolling behavior
    const touch = e.touches[0];
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (touch.clientX - rect.left) * scaleX;
    const y = (touch.clientY - rect.top) * scaleY;
    console.log(`Touch start position: x=${x}, y=${y}`);

    selectedImg = null;  // Reset selected image at start of new selection
    userImgs.forEach((imgObj, index) => {
      const imgWidth = imgObj.width || imgObj.img.width;
      const imgHeight = imgObj.height || imgObj.img.height;
      console.log(`Checking image ${index} at (${imgObj.x}, ${imgObj.y}) with width ${imgWidth} and height ${imgHeight}`);

      if (x >= imgObj.x && x <= imgObj.x + imgWidth && y >= imgObj.y && y <= imgObj.y + imgHeight) {
        selectedImg = imgObj;
        startX = x - imgObj.x;
        startY = y - imgObj.y;
        lastMovedImg = imgObj; // Store original position before moving
      }
    });

    if (!selectedImg) {
      console.log('No image was selected for movement.');
    }
  }

  function move_onTouchMove(e) {
    e.preventDefault(); // Prevents the default scrolling behavior
    if (!selectedImg) return;

    const touch = e.touches[0];
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (touch.clientX - rect.left) * scaleX;
    const y = (touch.clientY - rect.top) * scaleY;

    selectedImg.x = x - startX;
    selectedImg.y = y - startY;
    console.log(`Moving image to x=${selectedImg.x}, y=${selectedImg.y}`);

    updateCanvas();
  }

  function move_onTouchEnd() {
    if (selectedImg) {
      console.log(`Movement stopped for selected image at x=${selectedImg.x}, y=${selectedImg.y}`);
    }
    selectedImg = null;
  }

  function activateMoveMode() {
    activeMode = 'move';
    acceptBtn.disabled = false;
    acceptBtn.classList.remove("imgAct-disabled");
    rejectBtn.disabled = false;
    rejectBtn.classList.remove("imgAct-disabled");
    moveImgBtn.classList.add("imgAct-selected");
    canvas.style.cursor = 'move';
    resizeImgBtn.disabled = true;
    resizeImgBtn.classList.add("imgAct-disabled");
    rotateImgBtn.disabled = true;
    rotateImgBtn.classList.add("imgAct-disabled");
    delImgBtn.disabled = true;
    delImgBtn.classList.add("imgAct-disabled");
    addTopTextBtn.disabled = true;
    addTopTextBtn.classList.add("imgAct-disabled"); 
    addBottomTextBtn.disabled = true;
    addBottomTextBtn.classList.add("imgAct-disabled"); 

    canvas.addEventListener('mousedown', move_onMouseDown);
    canvas.addEventListener('mousemove', move_onMouseMove);
    canvas.addEventListener('mouseup', move_onMouseUp);
    canvas.addEventListener('touchstart', move_onTouchStart);
    canvas.addEventListener('touchmove', move_onTouchMove);
    canvas.addEventListener('touchend', move_onTouchEnd);
  }

  function deactivateMoveMode() {
    console.log("Move mode deactivated.");
    canvas.style.cursor = 'default';
    moveImgBtn.classList.remove("imgAct-selected");
    acceptBtn.classList.add("imgAct-disabled");
    rejectBtn.classList.add("imgAct-disabled");
    acceptBtn.disabled = true;
    rejectBtn.disabled = true;
    resizeImgBtn.disabled = false;
    resizeImgBtn.classList.remove("imgAct-disabled");
    rotateImgBtn.disabled = false;
    rotateImgBtn.classList.remove("imgAct-disabled");
    delImgBtn.disabled = false;
    delImgBtn.classList.remove("imgAct-disabled");
    addTopTextBtn.disabled = false;
    addTopTextBtn.classList.remove("imgAct-disabled"); 
    addBottomTextBtn.disabled = false;
    addBottomTextBtn.classList.remove("imgAct-disabled"); 

    canvas.removeEventListener('mousedown', move_onMouseDown);
    canvas.removeEventListener('mousemove', move_onMouseMove);
    canvas.removeEventListener('mouseup', move_onMouseUp);
    canvas.removeEventListener('touchstart', move_onTouchStart);
    canvas.removeEventListener('touchmove', move_onTouchMove);
    canvas.removeEventListener('touchend', move_onTouchEnd);
  }

  // DELETE FUNCTIONS
  let overlayedImg = null;

  function activateDeleteMode() {
    activeMode = 'delete';
    delImgBtn.classList.add("imgAct-selected");
    canvas.style.cursor = 'pointer';
    acceptBtn.disabled = false;
    acceptBtn.classList.remove("imgAct-disabled");
    rejectBtn.disabled = false;
    rejectBtn.classList.remove("imgAct-disabled");
    resizeImgBtn.disabled = true;
    resizeImgBtn.classList.add("imgAct-disabled");
    moveImgBtn.disabled = true;
    moveImgBtn.classList.add("imgAct-disabled");
    rotateImgBtn.disabled = true;
    rotateImgBtn.classList.add("imgAct-disabled");
    addTopTextBtn.disabled = true;
    addTopTextBtn.classList.add("imgAct-disabled"); 
    addBottomTextBtn.disabled = true;
    addBottomTextBtn.classList.add("imgAct-disabled"); 
  }

  function deactivateDeleteMode() {
    overlayedImg = null;
    activeMode = null;
    delImgBtn.classList.remove("imgAct-selected");
    canvas.style.cursor = 'default';
    acceptBtn.disabled = true;
    acceptBtn.classList.add("imgAct-disabled");
    rejectBtn.disabled = true;
    rejectBtn.classList.add("imgAct-disabled");
    resizeImgBtn.disabled = false;
    resizeImgBtn.classList.remove("imgAct-disabled");
    moveImgBtn.disabled = false;
    moveImgBtn.classList.remove("imgAct-disabled");
    rotateImgBtn.disabled = false;
    rotateImgBtn.classList.remove("imgAct-disabled");
    addTopTextBtn.disabled = false;
    addTopTextBtn.classList.remove("imgAct-disabled"); 
    addBottomTextBtn.disabled = false;
    addBottomTextBtn.classList.remove("imgAct-disabled"); 
    updateCanvas();
  }

  function deleteImage() {
    if (overlayedImg && overlayedImg !== ceicatImgObj) {
      const index = userImgs.indexOf(overlayedImg);
      if (index > -1) {
        userImgs.splice(index, 1);
      }
      updateCanvas();
      overlayedImg = null;
    }
  }

  function deleteText(x, y) {
    const textHitBoxHeight = 40;
    const textHitBoxWidth = 100;
    const topTextY = 52; // Y coordinate for top text
    const bottomTextY = canvas.height - 16; // Y coordinate for bottom text
    if (topText && y <= topTextY + textHitBoxHeight / 2 && y >= topTextY - textHitBoxHeight / 2 && x >= canvas.width / 2 - textHitBoxWidth / 2 && x <= canvas.width / 2 + textHitBoxWidth / 2) {
      topText = '';
      updateCanvas();
    }
    if (bottomText && y >= bottomTextY - textHitBoxHeight / 2 && y <= bottomTextY + textHitBoxHeight / 2 && x >= canvas.width / 2 - textHitBoxWidth / 2 && x <= canvas.width / 2 + textHitBoxWidth / 2) {
      bottomText = '';
      updateCanvas();
    }
  }

  canvas.addEventListener('click', function(e) {
    if (activeMode === 'delete') {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;

      let foundImg = false;

      userImgs.forEach(imgObj => {
        if (x >= imgObj.x && x <= imgObj.x + (imgObj.width || imgObj.img.width) && y >= imgObj.y && y <= imgObj.y + (imgObj.height || imgObj.img.height)) {
          overlayedImg = imgObj;
          foundImg = true;
        }
      });

      if (!foundImg) {
        deleteText(x, y);
      }
    }
  });

  delImgBtn.addEventListener('click', () => {
    if (activeMode !== 'delete') {
      deactivateCurrentMode();
      activateDeleteMode();
    }
  });

  acceptBtn.addEventListener('click', () => {
    if (activeMode === 'textTopMode') {
      topText = textBox.value;
      updateCanvas();
      deactivateCurrentMode();
    } else if (activeMode === 'textBottomMode') {
      bottomText = textBox.value;
      updateCanvas();
      deactivateCurrentMode();
    }
    if (activeMode === 'delete') {
      deleteImage();
      deactivateDeleteMode();
    }
    if (activeMode === 'rotate') {
      deactivateRotateMode();
    }
    console.log("Accept clicked, current mode: ", activeMode);
    deactivateCurrentMode();
    console.log("deactiving mode, current mode: ", activeMode);
  });

  rejectBtn.addEventListener('click', () => {
    console.log("Reject clicked, current mode: ", activeMode);
    if (activeMode === 'move' && lastMovedImg) {
      lastMovedImg.x = lastMovedImg.img.x;
      lastMovedImg.y = lastMovedImg.img.y;
      
      updateCanvas();
    }
    if (activeMode === 'resize' && lastResizedImg) {
      console.log(lastResizedImg.img.width);
      console.log(lastResizedImg.width);
      console.log("Resetting to original size:", lastResizedImg.originalWidth, "x", lastResizedImg.originalHeight);
      lastResizedImg.img.width = lastResizedImg.originalWidth;
      lastResizedImg.img.height = lastResizedImg.originalHeight;
      lastResizedImg.width = lastResizedImg.originalWidth;
      lastResizedImg.height = lastResizedImg.originalHeight;
      updateCanvas();
    }
    if (activeMode === 'delete') {
      overlayedImg = null;
      deactivateDeleteMode();
    }
    if (activeMode === 'rotate') {
      if (selectedImg_forRotate) {
        selectedImg_forRotate.rotationAngle = selectedImg_forRotate.originalRotation || 0;
        updateCanvas();
      }
      deactivateRotateMode();
    }
    deactivateCurrentMode();
    console.log("deactiving mode, current mode: ", activeMode);
  });

  moveImgBtn.addEventListener('click', () => {
    if (activeMode !== 'move') {
      deactivateCurrentMode();
      activateMoveMode();
    }
  });

  resizeImgBtn.addEventListener('click', () => {
    if (activeMode !== 'resize') {
      deactivateCurrentMode();
      activateResizeMode();
    }
  });

  rotateImgBtn.addEventListener('click', () => {
    if (activeMode !== 'rotate') {
      deactivateCurrentMode();
      activateRotateMode();
    }
  });

  delImgBtn.addEventListener('click', () => {
    if (activeMode !== 'delete') {
      deactivateCurrentMode();
      activateDeleteMode();
    }
  });

  // ROTATE IMGS FUNCTIONS
  let rotating = false;
  let lastMouseX = null;
  let lastMouseY = null;

  function getAngle(cx, cy, ex, ey) {
    const dy = ey - cy;
    const dx = ex - cx;
    const rad = Math.atan2(dy, dx); // range (-PI, PI)
    const deg = rad * (180 / Math.PI); // radians to degrees, range (-180, 180)
    return deg;
  }

  function rotate_selectImage(x, y) {
    selectedImg_forRotate = null;
    userImgs.forEach(imgObj => {
      if (x >= imgObj.x && x <= imgObj.x + (imgObj.width || imgObj.img.width) && y >= imgObj.y && y <= imgObj.y + (imgObj.height || imgObj.img.height)) {
        selectedImg_forRotate = imgObj;
      }
    });

    // Toggle rotation input visibility based on whether an image is selected
    rotateDegInput.style.display = selectedImg_forRotate ? 'block' : 'none';
    if (selectedImg_forRotate) {
      rotateDegInput.value = selectedImg_forRotate.rotationAngle || 0;  // Set current rotation as the input value
      selectedImg_forRotate.originalRotation = selectedImg_forRotate.rotationAngle || 0; // Store original rotation
    }
  }

  function rotate_onMouseDown(e) {
    const rect = canvas.getBoundingClientRect();
    const x = (e.clientX - rect.left) * (canvas.width / rect.width);
    const y = (e.clientY - rect.top) * (canvas.height / rect.height);
    rotate_selectImage(x, y);
  
    if (selectedImg_forRotate) {
      lastMouseX = x;
      lastMouseY = y;
      rotating = true;
      selectedImg_forRotate.startAngle = getAngle(selectedImg_forRotate.x + (selectedImg_forRotate.width || selectedImg_forRotate.img.width) / 2, selectedImg_forRotate.y + (selectedImg_forRotate.height || selectedImg_forRotate.img.height) / 2, x, y);
      selectedImg_forRotate.currentRotation = selectedImg_forRotate.rotationAngle || 0;
    }
  }

  function rotate_onMouseMove(e) {
    if (!selectedImg_forRotate || !rotating) return;
  
    const rect = canvas.getBoundingClientRect();
    const x = (e.clientX - rect.left) * (canvas.width / rect.width);
    const y = (e.clientY - rect.top) * (canvas.height / rect.height);
  
    const imgCenterX = selectedImg_forRotate.x + (selectedImg_forRotate.width || selectedImg_forRotate.img.width) / 2;
    const imgCenterY = selectedImg_forRotate.y + (selectedImg_forRotate.height || selectedImg_forRotate.img.height) / 2;
    
    const newAngle = getAngle(imgCenterX, imgCenterY, x, y);
    const oldAngle = getAngle(imgCenterX, imgCenterY, lastMouseX, lastMouseY);
  
    selectedImg_forRotate.rotationAngle = selectedImg_forRotate.currentRotation + (newAngle - oldAngle);
    lastMouseX = x;
    lastMouseY = y;
  
    updateCanvas();
  }

  function rotate_onMouseUp() {
    if (rotating && selectedImg_forRotate) {
      selectedImg_forRotate.currentRotation = selectedImg_forRotate.rotationAngle;
      rotating = false;
    }
  }

  function rotate_onTouchStart(e) {
    e.preventDefault();
    const touch = e.touches[0];
    const rect = canvas.getBoundingClientRect();
    const x = (touch.clientX - rect.left) * (canvas.width / rect.width);
    const y = (touch.clientY - rect.top) * (canvas.height / rect.height);
    rotate_selectImage(x, y);
  
    if (selectedImg_forRotate) {
      rotating = true;
      selectedImg_forRotate.startAngle = getAngle(selectedImg_forRotate.x + (selectedImg_forRotate.width || selectedImg_forRotate.img.width) / 2, selectedImg_forRotate.y + (selectedImg_forRotate.height || selectedImg_forRotate.img.height) / 2, x, y) - selectedImg_forRotate.rotationAngle;
    }
  }

  function rotate_onTouchMove(e) {
    e.preventDefault();
    if (!selectedImg_forRotate || !rotating) return;
  
    const touch = e.touches[0];
    const rect = canvas.getBoundingClientRect();
    const x = (touch.clientX - rect.left) * (canvas.width / rect.width);
    const y = (touch.clientY - rect.top) * (canvas.height / rect.height);
  
    const imgCenterX = selectedImg_forRotate.x + (selectedImg_forRotate.width || selectedImg_forRotate.img.width) / 2;
    const imgCenterY = selectedImg_forRotate.y + (selectedImg_forRotate.height || selectedImg_forRotate.img.height) / 2;
    const currentAngle = getAngle(imgCenterX, imgCenterY, x, y);
    selectedImg_forRotate.rotationAngle = currentAngle - selectedImg_forRotate.startAngle;
  
    updateCanvas();
  }
  
  function rotate_onTouchEnd() {
    if (rotating && selectedImg_forRotate) {
      rotating = false;
    }
  }

  function activateRotateMode() {
    activeMode = 'rotate';
    rotateDegInput.style.display = 'block'; // Show the input

    // Set the value only if there's already a rotation, otherwise show placeholder
    if (selectedImg_forRotate && selectedImg_forRotate.rotationAngle) {
        rotateDegInput.value = selectedImg_forRotate.rotationAngle;
    } else {
        rotateDegInput.value = ''; // Clear the input to show placeholder
    }

    // Ensure this listener is added only once, otherwise, it can create multiple listeners
    rotateDegInput.removeEventListener('input', handleInputRotation);
    rotateDegInput.addEventListener('input', handleInputRotation);

    function handleInputRotation() {
      if (selectedImg_forRotate) {
        // Validate the input to ensure it's a number
        const parsedValue = parseInt(this.value);
        if (!isNaN(parsedValue)) {
          selectedImg_forRotate.rotationAngle = parsedValue;
          updateCanvas();
        }
      }
    }

    acceptBtn.disabled = false;
    acceptBtn.classList.remove("imgAct-disabled");
    rejectBtn.disabled = false;
    rejectBtn.classList.remove("imgAct-disabled");
    rotateImgBtn.classList.add("imgAct-selected");
    canvas.style.cursor = 'grab';
    moveImgBtn.disabled = true;
    moveImgBtn.classList.add("imgAct-disabled");
    resizeImgBtn.disabled = true;
    resizeImgBtn.classList.add("imgAct-disabled");
    delImgBtn.disabled = true;
    delImgBtn.classList.add("imgAct-disabled");

    canvas.addEventListener('mousedown', rotate_onMouseDown);
    canvas.addEventListener('mousemove', rotate_onMouseMove);
    canvas.addEventListener('mouseup', rotate_onMouseUp);
    canvas.addEventListener('touchstart', rotate_onTouchStart);
    canvas.addEventListener('touchmove', rotate_onTouchMove);
    canvas.addEventListener('touchend', rotate_onTouchEnd);
  }

  function deactivateRotateMode() {
    rotateDegInput.style.display = 'none';
    rotateDegInput.removeEventListener('input', function() {
        // Cleanup listener, though this is a no-op as written
    });
    activeMode = null;
    console.log("Resize mode deactivated.");
    canvas.style.cursor = 'default';
    rotateImgBtn.classList.remove("imgAct-selected");
    acceptBtn.classList.add("imgAct-disabled");
    rejectBtn.classList.add("imgAct-disabled");
    acceptBtn.disabled = true;
    rejectBtn.disabled = true;
    moveImgBtn.disabled = false;
    moveImgBtn.classList.remove("imgAct-disabled");
    resizeImgBtn.disabled = false;
    resizeImgBtn.classList.remove("imgAct-disabled");
    delImgBtn.disabled = false;
    delImgBtn.classList.remove("imgAct-disabled");

    canvas.removeEventListener('mousedown', rotate_onMouseDown);
    canvas.removeEventListener('mousemove', rotate_onMouseMove);
    canvas.removeEventListener('mouseup', rotate_onMouseUp);
    canvas.removeEventListener('touchstart', rotate_onTouchStart);
    canvas.removeEventListener('touchmove', rotate_onTouchMove);
    canvas.removeEventListener('touchend', rotate_onTouchEnd);
    rotating = false;
  }

  updateCanvas();

  bgFileInput.addEventListener('change', (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          userBgImg = img;
          loadBackgroundImage(userBgImg.src);
        };
      };
      reader.readAsDataURL(file);
    }
  });

  imgFileInput.addEventListener('change', (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          userImgs.push({ img: img, x: (canvas.width - img.width) / 2, y: (canvas.height - img.height) / 2 + userImgs.length * 20 });
          updateCanvas();
        };
      };
      reader.readAsDataURL(file);
    }
    // Clear the file input value to allow uploading the same file again
    event.target.value = '';
  });

  bgBtn.addEventListener('click', () => {
    bgFileInput.click();
  });

  imgBtn.addEventListener('click', () => {
    imgFileInput.click();
  });

  downloadBtn.addEventListener('click', function() {
    const getRand = Math.floor(100000 + Math.random() * 900000); // gen random 6-digit number for file name
    const getDate = new Date();
    const getDateFormat = getDate.toISOString().split('T')[0]; // formats the date as YYYY-MM-DD
    const fileName = `${getRand}-${getDateFormat}.png`;
    const link = document.createElement('a');
    link.download = fileName;
    link.href = canvas.toDataURL();
    link.click();
  });
}

main.appendChild(memeGenContainer);

// HOW TO BUY

const step1svg = `<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H6V6H0V0ZM2 2V4H4V2H2Z" fill="#f5e0dc"/>
</svg>`;
const step2svg = `<svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H6V6H0V0ZM2 2V4H4V2H2Z" fill="#f5e0dc"/>
<path d="M8 0H14V6H8V0ZM10 2V4H12V2H10Z" fill="#f38ba8"/>
</svg>`;
const step3svg = `<svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H6V6H0V0ZM2 2V4H4V2H2Z" fill="#f5e0dc"/>
<path d="M8 0H14V6H8V0ZM10 2V4H12V2H10Z" fill="#f38ba8"/>
<path d="M16 0H22V6H16V0ZM18 2V4H20V2H18Z" fill="#fab387"/>
</svg>`;
const step4svg = `<svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H6V6H0V0ZM2 2V4H4V2H2Z" fill="#f5e0dc"/>
<path d="M8 0H14V6H8V0ZM10 2V4H12V2H10Z" fill="#f38ba8"/>
<path d="M16 0H22V6H16V0ZM18 2V4H20V2H18Z" fill="#fab387"/>
<path d="M24 0H30V6H24V0ZM26 2V4H28V2H26Z" fill="#a6e3a1"/>
</svg>`;
const step5svg = `<svg width="38" height="6" viewBox="0 0 38 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H6V6H0V0ZM2 2V4H4V2H2Z" fill="#f5e0dc"/>
<path d="M8 0H14V6H8V0ZM10 2V4H12V2H10Z" fill="#f38ba8"/>
<path d="M16 0H22V6H16V0ZM18 2V4H20V2H18Z" fill="#fab387"/>
<path d="M24 0H30V6H24V0ZM26 2V4H28V2H26Z" fill="#a6e3a1"/>
<path d="M32 0H38V6H32V0ZM34 2V4H36V2H34Z" fill="#89b4fa"/>
</svg>`;
const step6svg = `<svg width="46" height="6" viewBox="0 0 46 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H6V6H0V0ZM2 2V4H4V2H2Z" fill="#f5e0dc"/>
<path d="M8 0H14V6H8V0ZM10 2V4H12V2H10Z" fill="#f38ba8"/>
<path d="M16 0H22V6H16V0ZM18 2V4H20V2H18Z" fill="#fab387"/>
<path d="M24 0H30V6H24V0ZM26 2V4H28V2H26Z" fill="#a6e3a1"/>
<path d="M32 0H38V6H32V0ZM34 2V4H36V2H34Z" fill="#89b4fa"/>
<path d="M40 0H46V6H40V0ZM42 2V4H44V2H42Z" fill="#cba6f7"/>
</svg>`;

const howToBuy = document.createElement('div');
howToBuy.id = "howToBuy";
main.appendChild(howToBuy);
function howToBuyContent() {
  const header = document.createElement('h1'); 
  header.classList.add("header");
  header.innerHTML = `How to buy $CEICAT?`;

  const steps = document.createElement('div');
  steps.classList.add("steps");

  function Steps(icon, headerContent, spanContent) {
    this.container = document.createElement('div');
    this.container.classList.add('stepContainer');

    this.icon = document.createElement('div');
    this.icon.innerHTML = icon;
    this.icon.classList.add("stepIcon");
    this.container.appendChild(this.icon);

    this.header = document.createElement('span');
    this.header.innerHTML =headerContent;
    this.header.classList.add("stepHeader");
    this.container.appendChild(this.header);

    this.span = document.createElement('span');
    this.span.innerHTML = spanContent;
    this.span.classList.add("stepSpan");
    this.container.appendChild(this.span);

    steps.appendChild(this.container);
  }

  new Steps(step1svg, 
    'Step 1', 
    `Install a solana wallet like <a href="https://phantom.app/" target="_blank">Phantom Wallet</a>`);
  new Steps(step2svg, 
    'Step 2', 
    'Acquire some $SOL to trade & transfer to wallet, come back here, and connect your solana wallet');
  new Steps(step3svg, 
    'Step 3', 
    'Enter the amount of $SOL to buy with and click on Swap');
  new Steps(step4svg, 
    'Step 4', 
    'Accept transaction, welcome to the Ceiling Cat community! Join chat from "Socials..."');

  const buyContainer = document.createElement('div');
  buyContainer.id = "buyContainer";
  const jupScript = document.createElement('script');
  jupScript.src = "https://terminal.jup.ag/main-v1.js";
  jupScript.setAttribute('data-preload', '');
  jupScript.async = true;
  document.head.appendChild(jupScript);
  jupScript.onload = () => {
    window.Jupiter.init({
      displayMode: "integrated",
      integratedTargetId: "buyContainer",
      endpoint: "https://solana-mainnet.g.alchemy.com/v2/Fqd58LIvoj4jV8m7Sv68J1vhOUUELDw9",
      formProps: {
        fixedInputMint: false,
        fixedOutputMint: false,
        initialInputMint: "So11111111111111111111111111111111111111112",
        initialOutputMint: contract.innerHTML,
      }
    });
  };

  howToBuy.appendChild(header);
  howToBuy.appendChild(steps);
  howToBuy.appendChild(buyContainer);
}

const glossary = document.createElement('div');
glossary.id = 'glossary';
main.appendChild(glossary);
function glossaryContent() {
  const header = document.createElement('h1');
  header.innerHTML = `Glossary`;
  header.id = "glossaryHeader";
  glossary.appendChild(header);

  const desc = document.createElement('span');
  desc.id = "glossaryDesc";
  desc.innerHTML = `New to the space? Get yourself familiar with the lingo before jumping back in the trenches ^^`;
  glossary.appendChild(desc);

  const letterContainers = {};
  let alignFlag = true;

  const doubleGlossaryContainer = document.createElement('div');
  doubleGlossaryContainer.id = "doubleGlossaryContainer";
  glossary.appendChild(doubleGlossaryContainer);

  function createLetterContainers(letter) {
    const container = document.createElement('div');
    container.classList.add('letters');
    container.id = `letter_${letter}`;

    const letterHeader = document.createElement('div');
    letterHeader.innerHTML = `*${letter}*`;
    letterHeader.classList.add('letterHeader');
    container.appendChild(letterHeader);

    container.style.alignSelf = alignFlag ? 'flex-start' : 'flex-end';
    alignFlag = !alignFlag;

    letterContainers[letter] = container;
    doubleGlossaryContainer.appendChild(container);
  }

  function Acronyms(letter, abbreviation, meaning) {
    if (!letterContainers[letter]) {
      createLetterContainers(letter);
    }

    this.container = document.createElement('div'); 
    this.container.classList.add('acronymContainer');

    this.abbreviation = document.createElement('span');
    this.abbreviation.innerHTML =  abbreviation;
    this.abbreviation.classList.add('glossaryHeader');
    this.container.appendChild(this.abbreviation);

    this.meaning = document.createElement('span');
    this.meaning.innerHTML = meaning;
    this.meaning.classList.add('glossaryMeaning');
    this.container.appendChild(this.meaning);
    
    letterContainers[letter].appendChild(this.container);
  }

  new Acronyms(`A`, `Address`, `A string of alphanumeric characters used to receive cryptocurrencies`);
  new Acronyms(`A`, `Airdrop`, `Distribution of free tokens to holders of a particular cryptocurrency`);
  new Acronyms(`A`, `Altcoin`, `Any cryptocurrency other than Bitcoin`);
  new Acronyms(`A`, `ATH (All-Time High)`, `The highest price ever reached by a cryptocurrency`);
  new Acronyms(`A`, `ATL (All-Time Low)`, `The lowest price ever reached by a cryptocurrency`);

  new Acronyms(`B`, `Bagholder`, `Someone holding a cryptocurrency that has fallen in value`); 
  new Acronyms(`B`, `Bear Market`, `A market condition where prices are falling or expected to fall`); 
  new Acronyms(`B`, `Block`, `A group of transactions recorded on a blockchain`); 
  new Acronyms(`B`, `Blockchain`, `A decentralized ledger of all transactions across a network`); 
  new Acronyms(`B`, `Bull Market`, `A market condition where prices are rising or expected to rise`); 

  new Acronyms(`C`, `Ceiling Cat`, `A memetic character symbolizing omniscience and watchfulness in the crypto space`);
  new Acronyms(`C`, `Cold Wallet`, `An offline wallet for storing cryptocurrency`);
  new Acronyms(`C`, `Consensus Algorithm`, `A mechanism used to achieve agreement on a blockchain`);
  new Acronyms(`C`, `Cryptocurrency`, `A digital or virtual currency secured by cryptography`);
  new Acronyms(`C`, `Crypto Exchange`, `A platform for buying, selling, and trading cryptocurrencies`);

  new Acronyms(`D`, `dApp (Decentralized Application)`, `An application that runs on a decentralized network`);
  new Acronyms(`D`, `DAO (Decentralized Autonomous Organization)`, `An organization governed by smart contracts and consensus mechanisms`);
  new Acronyms(`D`, `DeFi (Decentralized Finance)`, `Financial services on blockchain networks without intermediaries`);
  new Acronyms(`D`, `Diamond Hands`, `Holding onto an asset despite volatility and potential losses`);
  new Acronyms(`D`, `Doxxing`, `Publishing private or identifying information about an individual without consent`);

  new Acronyms(`E`, `ERC-20`, `A standard for tokens on the Ethereum blockchain`);
  new Acronyms(`E`, `Exchange`, `A marketplace for trading cryptocurrencies`);
  new Acronyms(`E`, `EVM (Ethereum Virtual Machine)`, `A runtime environment for smart contracts in Ethereum`);

  new Acronyms(`F`, `Fiat`, `Traditional government-issued currency`);
  new Acronyms(`F`, `FOMO (Fear of Missing Out)`, `Anxiety that one may miss a profitable investment opportunity`);
  new Acronyms(`F`, `Fork`, `A change in a blockchain's protocol or code`);
  new Acronyms(`F`, `FUD (Fear, Uncertainty, and Doubt)`, `Negative information spread to influence perception and price`);
  
  new Acronyms(`G`, `Gas`, `A fee paid for transactions on the Ethereum network`); 
  new Acronyms(`G`, `Genesis Block`, `The first block of a blockchain`);

  new Acronyms(`H`, `Halving`, `A process where the reward for mining a block is cut in half`);
  new Acronyms(`H`, `HODL`, `A misspelling of "hold," referring to holding onto cryptocurrency rather than selling`);

  new Acronyms(`I`, `ICO (Initial Coin Offering)`, `A fundraising method where new cryptocurrencies are sold to early investors`);
  new Acronyms(`I`, `Immutable`, `Cannot be changed or altered`);

  new Acronyms(`J`, `JOMO (Joy of Missing Out)`, `Feeling content with missing out on a trend or investment`);

  new Acronyms(`K`, `KYC (Know Your Customer)`, `A process to verify the identity of users`);

  new Acronyms(`L`, `Ledger`, `A record of financial transactions`);
  new Acronyms(`L`, `Liquidity`, `The ease with which an asset can be converted into cash`);

  new Acronyms(`M`, `Mainnet`, `The main network where a blockchain operates`);
  new Acronyms(`M`, `Market Cap/Capitalization`, `The total value of all coins of a cryptocurrency`);
  new Acronyms(`M`, `Mempool`, `A collection of unconfirmed transactions on a blockchain`);
  new Acronyms(`M`, `Mining`, `The process of verifying transactions and adding them to the blockchain`);

  new Acronyms(`N`, `Node`, `A computer that participates in a blockchain network`);
  new Acronyms(`N`, `NFT (Non-Fungible Token)`, `A unique digital asset verified on the blockchain`);
  new Acronyms(`N`, `Nonce`, `A number used once in a cryptographic communication`);

  new Acronyms(`O`, `Oracle`, `A service that provides external data to smart contracts`);
  new Acronyms(`O`, `OTC (Over-The-Counter)`, `Trading conducted directly between parties without an exchange`);

  new Acronyms(`P`, `P2P (Peer-to-Peer)`, `Transactions conducted directly between users`);
  new Acronyms(`P`, `Private Key`, `A secret key that allows access to cryptocurrency`);
  new Acronyms(`P`, `Proof of Stake (PoS)`, `A consensus mechanism where validators are chosen based on their stake in the network`);
  new Acronyms(`P`, `Proof of Work (PoW)`, `A consensus mechanism where miners solve complex mathematical problems to validate transactions`);

  new Acronyms(`Q`, `QR Code`, `A machine-readable code used to share cryptocurrency addresses`);

  new Acronyms(`R`, `Rug Pull`, `A scam where developers abandon a project and run off with investors' funds`);

  new Acronyms(`S`, `Satoshi`, `The smallest unit of Bitcoin, named after its creator`);
  new Acronyms(`S`, `Smart Contract`, `A self-executing contract with the terms directly written into code`);
  new Acronyms(`S`, `Stablecoin`, `A cryptocurrency pegged to a stable asset like fiat currency`);
  new Acronyms(`S`, `Sharding`, `A method of partitioning a blockchain into smaller, more manageable pieces`);
  new Acronyms(`S`, `Solana`, `A high-performance blockchain supporting fast and low-cost transactions`);
  new Acronyms(`S`, `SOL`, `The native cryptocurrency of the Solana blockchain`);
  new Acronyms(`S`, `Staking`, `The process of holding cryptocurrency in a wallet to support the operations of a blockchain network, often rewarded with more tokens`);
  new Acronyms(`S`, `Solana Wallet`, `A wallet used specifically for managing Solana and SOL-based tokens`);
  new Acronyms(`S`, `Serum`, `A decentralized exchange built on Solana`);

  new Acronyms(`T`, `Token`, `A digital asset issued on a blockchain`);
  new Acronyms(`T`, `Transaction Fee`, `A fee paid to process transactions on a blockchain`);
  new Acronyms(`T`, `TDS (Transaction Data Structure)`, `The format in which transactions are organized and stored on Solana`);

  new Acronyms(`U`, `Utility Token`, `A token that provides access to a product or service`);

  new Acronyms(`V`, `Validator`, `A participant in a PoS network that validates transactions`);
  new Acronyms(`V`, `Volatality`, `The degree of variation in the price of a cryptocurrency`);

  new Acronyms(`W`, `Wallet`, `A software or hardware device to store and manage cryptocurrencies`);
  new Acronyms(`W`, `Whale`, `An individual or entity that holds a large amount of cryptocurrency`);

  new Acronyms(`X`, `XRP`, `The native cryptocurrency of the Ripple network`);

  new Acronyms(`Y`, `Yield Farming`, `Earning rewards by lending or staking cryptocurrencies`);

  new Acronyms(`Z`, `Zero-Knowledge Proof`, `A cryptographic method that allows one party to prove to another that they know a value without revealing the value itself`);
}

const catWatchText = document.createElement('span');
catWatchText.id = "catWatchText";
catWatchText.innerHTML = `DON'T FORGET THE CAT IS WATCHING YOU!`;
main.appendChild(catWatchText);
const catWatchImg = document.createElement('img');
catWatchImg.id = "catWatchImg";
catWatchImg.src = ceicatImg;
main.appendChild(catWatchImg);

// Footer
const footer = document.querySelector('footer');

const footerBtns = document.createElement('div');
footerBtns.id = "footerBtns";

const socialsBtn = document.createElement('button');
socialsBtn.innerHTML = `Socials...`;
socialsBtn.id = "socialsBtn";
footerBtns.appendChild(socialsBtn);

const socials = document.createElement('div');
socials.classList.add("socials");

const telegramBtn = document.createElement('button');
telegramBtn.innerHTML = `Telegram`;
telegramBtn.id = "telegramBtn";
socials.appendChild(telegramBtn);

const twitterBtn = document.createElement('button');
twitterBtn.innerHTML = `Twitter`;
twitterBtn.id = "twitterBtn";
socials.appendChild(twitterBtn);

const tiktokBtn = document.createElement('button');
tiktokBtn.innerHTML = `Tiktok`;
tiktokBtn.id = "tiktokBtn";
socials.appendChild(tiktokBtn);

const youtubeBtn = document.createElement('button');
youtubeBtn.innerHTML = `YouTube`;
youtubeBtn.id = "youtubeBtn";
socials.appendChild(youtubeBtn);

footerBtns.appendChild(socials);

const buyAction = document.createElement('div');
buyAction.classList.add("buyAction");

const moreChartsBtn = document.createElement('button');
moreChartsBtn.innerHTML = `Charts & More...`;
moreChartsBtn.id = "moreChartsBtn";
buyAction.appendChild(moreChartsBtn);

const moreCharts = document.createElement('div');
moreCharts.classList.add("moreCharts");
moreCharts.style.display = 'none';

const coinmarketcapBtn = document.createElement('button');
coinmarketcapBtn.innerHTML = `CMC`;
coinmarketcapBtn.id = `cmcBtn`;
moreCharts.appendChild(coinmarketcapBtn);

const coingeckoBtn = document.createElement('button');
coingeckoBtn.innerHTML = `Coingecko`;
coingeckoBtn.id = `coingeckoBtn`;
moreCharts.appendChild(coingeckoBtn);

const dexscreenerBtn = document.createElement('button');
dexscreenerBtn.innerHTML = `Dexscreener`;
moreCharts.appendChild(dexscreenerBtn);

const dextoolBtn = document.createElement('button');
dextoolBtn.innerHTML = `Dextool`;
moreCharts.appendChild(dextoolBtn);

const jupiterBtn = document.createElement('button');
jupiterBtn.innerHTML = `Birdeye`;
moreCharts.appendChild(jupiterBtn);

const solscanBtn = document.createElement('button');
solscanBtn.innerHTML = `Solscan`;
moreCharts.appendChild(solscanBtn);

buyAction.appendChild(moreCharts);

const buyBtn = document.createElement('button');
buyBtn.innerHTML = `Buy`;
buyBtn.id = "buyBtn";
buyAction.appendChild(buyBtn);

footerBtns.appendChild(buyAction);

footer.appendChild(footerBtns);

const footerAction = document.createElement('div');
footerAction.id = "footerAction";

const contractContainer = document.createElement('div');
contractContainer.classList.add("contract");

const contract = document.createElement('span');
contract.innerHTML = `8PMJczfs9W8TDKiNBD85AuqxE8tWACCDeUwxpUeadL3j`;
contract.id = "contract";
contractContainer.appendChild(contract);

const copyCaBtn = document.createElement('button');
copyCaBtn.innerHTML = `Copy`;
copyCaBtn.id = "copyCaBtn";
contractContainer.appendChild(copyCaBtn);

footerAction.appendChild(contractContainer);

function musicPlayer() {

  const musicBtn = document.createElement('button');
  musicBtn.id = "musicBtn";
  const musicImgIcon = document.createElement('img');
  musicImgIcon.src = musicIcon;
  musicBtn.appendChild(musicImgIcon);

  const musicWidget = document.createElement('div');
  musicWidget.id = "musicWidget";
  musicWidget.style.display = 'none';
  document.body.appendChild(musicWidget);

  let currentlyPlaying = null;

  function createMusicButton(title, audioSrc) {
    const button = document.createElement('button');
    button.innerHTML = title;
    const audio = new Audio(audioSrc);

    button.addEventListener('click', function() {
      if (currentlyPlaying && currentlyPlaying !== audio) {
        currentlyPlaying.pause();
        currentlyPlaying.currentTime = 0;
      }

      audio.play().catch(error => {
        console.log("Autoplay was prevented. Please interact with the page to play audio.", error);
      });

      currentlyPlaying = audio;
    });

    return { button, audio };
  }

  const music1 = createMusicButton('Crypto Cat', ceicat_cryptoCat);
  const music2 = createMusicButton('Crypto King', ceicat_cryptoKing);
  const music3 = createMusicButton('Eyes', ceicat_eyes);
  const music4 = createMusicButton('Fever', ceicat_fever);
  const music5 = createMusicButton('Sensation', ceicat_sensation);
  const music6 = createMusicButton('Viral', ceicat_viral);
  const music7 = createMusicButton('Undervalued', ceicat_catUndervalued);

  const allSongs = [music1, music2, music3, music4, music5, music6, music7];
  allSongs.forEach(song => {
    musicWidget.appendChild(song.button);
  });
  allSongs.forEach(song => {
    song.audio.loop = false; 
    song.audio.volume = 1.0;
  });

  let isMusicWidgetVisible = false;

  musicBtn.addEventListener('click', function() {
    event.stopPropagation();
    isMusicWidgetVisible = !isMusicWidgetVisible;
    if (isMusicWidgetVisible) {
      musicWidget.style.display = 'grid'; 
      musicBtn.innerHTML = `X`;
      musicBtn.style.color = "var(--red)";
      musicBtn.style.backgroundColor = "var(--textTranslucent)";
    } else {
      musicWidget.style.display = 'none';
      musicBtn.innerHTML = '';
      musicBtn.appendChild(musicImgIcon);
      musicBtn.style.backgroundColor = 'none';
    }

  });

  document.addEventListener('click', function(event) {
    if (isMusicWidgetVisible && !musicWidget.contains(event.target) && !musicBtn.contains(event.target)) {
      musicWidget.style.display = 'none';
      musicBtn.innerHTML = '';
      musicBtn.appendChild(musicImgIcon);
      musicBtn.style.color = "var(--lavender)";
      musicBtn.style.backgroundColor = "var(--textTranslucent)";
      isMusicWidgetVisible = false;
    }
  });

  footerAction.appendChild(musicBtn);
  footer.appendChild(footerAction);
}

footer.appendChild(footerAction);

document.addEventListener('DOMContentLoaded', function() {
  backdropEffects();
  catOriginsVid();
  musicPlayer();
  setTimeout(memeGen, 1000); // deplay memeGen by 1 second
  howToBuyContent();
  glossaryContent();


  const doubleGlossaryContainer = document.getElementById("doubleGlossaryContainer");
  doubleGlossaryContainer.addEventListener('scroll', function() {
    const glossaryElement = document.getElementById('glossary');
    const scrollHeight = doubleGlossaryContainer.scrollHeight;
    const scrollTop = doubleGlossaryContainer.scrollTop;
    const offsetHeight = doubleGlossaryContainer.offsetHeight;

    if (scrollTop + offsetHeight >= scrollHeight - 1) {
      glossaryElement.style.setProperty('--fade-opacity', '0');
    } else {
      glossaryElement.style.setProperty('--fade-opacity', '1');
    }
  });

  // reveeal more menu for socialsBtn
  telegramBtn.style.display = 'none';
  twitterBtn.style.display = 'none';
  tiktokBtn.style.display = 'none';
  youtubeBtn.style.display = 'none';
  let isSocialsVisible = false;

  socialsBtn.addEventListener('click', function() {
    event.stopPropagation();
    isSocialsVisible = !isSocialsVisible;
    if (isSocialsVisible) {
      telegramBtn.style.display = 'block';
      twitterBtn.style.display = 'block';
      tiktokBtn.style.display = 'block';
      youtubeBtn.style.display = 'block';
    } else {
      telegramBtn.style.display = 'none';
      twitterBtn.style.display = 'none';
      tiktokBtn.style.display = 'none';
      youtubeBtn.style.display = 'none'; 
    }
  });

  document.addEventListener('click', function(event) {
    if (isSocialsVisible && !socials.contains(event.target) && !socialsBtn.contains(event.target)) {
      telegramBtn.style.display = 'none';
      twitterBtn.style.display = 'none';
      tiktokBtn.style.display = 'none';
      youtubeBtn.style.display = 'none'; 
      isSocialsVisible = false;
    }
  });

  // reveal more menu for actionBuy Btn
  let isMoreChartsVisible = false;

  moreChartsBtn.addEventListener('click', function() {
    event.stopPropagation();
    isMoreChartsVisible = !isMoreChartsVisible;
    if (isMoreChartsVisible) {
      moreCharts.style.display = 'grid';
      moreChartsBtn.innerHTML = `X`;
      moreChartsBtn.style.color = "var(--red)";
      moreChartsBtn.style.backgroundColor = "var(--textTranslucent)";
    } else {
      moreCharts.style.display = 'none';
      moreChartsBtn.innerHTML = `Charts & More...`;
      moreChartsBtn.style.color = "var(--lavender)";
      moreChartsBtn.style.backgroundColor = "var(--textTranslucent)";
    }
  });

  document.addEventListener('click', function(event) {
    if (isMoreChartsVisible && !moreCharts.contains(event.target) && !moreChartsBtn.contains(event.target)) {
      moreCharts.style.display = 'none';
      moreChartsBtn.innerHTML = 'Charts & More...';
      moreChartsBtn.style.color = "var(--lavender)";
      moreChartsBtn.style.backgroundColor = "var(--textTranslucent)";
      isMoreChartsVisible = false;
    }
  });

  // Hyperlinks

  catKnowMoreBtn.addEventListener('click', () => {
    window.open('https://knowyourmeme.com/memes/ceiling-cat', '_blank');
  });
  pfpGenBtn.addEventListener('click', () => {
    window.open('https://generator.ceicatsol.com', '_self'); 
  });
  galleryBtn.addEventListener('click', () => {
    window.open('https://ceicatsol.pixpa.com/app/1WpZ', '_blank');
  });
  whitepaperBtn.addEventListener('click', () => {
    window.open('https://d67e413c-eca3-475c-bdba-5916a21598dc.filesusr.com/ugd/d03300_17e60ebc52d643118d123dd1324e8a1a.pdf', '_blank');
  });
  
  telegramBtn.addEventListener('click', () => {
    window.open('https://t.me/ceicatsol', '_blank');
  });
  twitterBtn.addEventListener('click', () => {
    window.open('https://twitter.com/Ceilingsolcat', '_blank');
  });
  tiktokBtn.addEventListener('click', () => {
    window.open('https://www.tiktok.com/@ceilingcatsol', '_blank');
  });
  youtubeBtn.addEventListener('click', () => {
    window.open('https://youtube.com/@SolCeilingCat', '_blank');
  });

  coinmarketcapBtn.addEventListener('click', () => {
    window.open('https://coinmarketcap.com/currencies/ceiling-cat/', '_blank');
  });
  coingeckoBtn.addEventListener('click', () => {
    window.open('https://www.coingecko.com/en/coins/ceiling-cat', '_blank');
  });
  dexscreenerBtn.addEventListener('click', () => {
    window.open('https://dexscreener.com/solana/3hxko6ar8w5ktdw5b1ts9zthkeeqazhivemlwhgudvms', '_blank');
  });
  dextoolBtn.addEventListener('click', () => {
    window.open('https://www.dextools.io/app/en/solana/pair-explorer/3HxKo6ar8W5ktdw5B1ts9zTHkEeQazhiVemLWHgudVmS?t=1713342718384', '_blank');
  });
  jupiterBtn.addEventListener('click', () => {
    window.open('https://birdeye.so/token/8PMJczfs9W8TDKiNBD85AuqxE8tWACCDeUwxpUeadL3j?chain=solana', '_blank');
  });
  solscanBtn.addEventListener('click', () => {
    window.open('https://solscan.io/token/8PMJczfs9W8TDKiNBD85AuqxE8tWACCDeUwxpUeadL3j', '_blank');
  });

  buyBtn.addEventListener('click', () => {
    howToBuy.scrollIntoView({ behavior: 'smooth' });
  });

  // COPY CA LOGIC
  const textToCopy = contract.innerHTML;
  copyCaBtn.addEventListener("click", async () => {
    try {
      // Try the modern Clipboard API first (if supported)
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied successfully using Clipboard API");
      alert("Contract copied successfully!");
    } catch (err) {
      // If Clipboard API fails, use the legacy approach
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed"; // Hide element off-screen
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied successfully using legacy approach");
      alert("Contract copied successfully!");
    }
  });
});